import * as React from "react";

import SubpageBanner from "../molecules/subpage-banner";
import ProjectsList from "../organisms/projects-list";
import Layout from "../layout/Layout";
import Topbar from "../molecules/topbar";
import Seo from "../layout/Seo";

const ProjectsPage = () => {
  return (
    <Layout>
      <Topbar />
      <SubpageBanner
        title={"Projects"}
        text={
          "Here's how we started strategic partnerships with our customers and helped their companies grow and scale."
        }
      />
      <ProjectsList />
    </Layout>
  );
};

export default ProjectsPage;

export const Head = () => <Seo title="Blockbit.es | Projects" />;
